(($) ->
  # privates

  setCaretTo = (element, start, end) ->
    if element.createTextRange
      range = element.createTextRange()
      range.moveStart 'character', start
      range.moveEnd 'character', end or start
      range.select()
    else if element.selectionStart
      element.focus()
      element.setSelectionRange start, end or start
    return

  getCaretPosition = (element) ->
    if typeof element.selectionStart == 'number'
      return element.selectionStart
    else if document.selection
      range = document.selection.createRange()
      rangeLength = range.text.length
      range.moveStart 'character', -element.value.length
      return range.text.length - rangeLength
    return

  $.fn.insertAtCaret = (myValue) ->
    @each ->
      me = this
      if document.selection
        # IE
        me.focus()
        sel = document.selection.createRange()
        sel.text = myValue
        me.focus()
      else if me.selectionStart or me.selectionStart == 0
        # Real browsers
        startPos = me.selectionStart
        endPos = me.selectionEnd
        scrollTop = me.scrollTop
        me.value = me.value.substring(0, startPos) + myValue + me.value.substring(endPos, me.value.length)
        me.focus()
        me.selectionStart = startPos + myValue.length
        me.selectionEnd = startPos + myValue.length
        me.scrollTop = scrollTop
      else
        me.value += myValue
        me.focus()
      return

  $.fn.setCaretPosition = (start, end) ->
    element = $(this).get(0)
    element.focus()
    setCaretTo element, start, end
    this

  $.fn.getCaretPosition = ->
    element = $(this).get(0)
    $(element).focus()
    getCaretPosition element

  $.fn.getSelectedText = ->
    element = $(this).get(0)
    # workaround for firefox because window.getSelection does not work inside inputs
    if typeof element.selectionStart == 'number'
      return $(element).val().substr(element.selectionStart, element.selectionEnd - (element.selectionStart))
    else if document.getSelection
      return document.getSelection()
    else if window.getSelection
      return window.getSelection()
    return

  return
) jQuery
