$.fn.draggable = (moveCallback) ->
  dragging = false
  clicking = false
  draggedLi = null
  placeholder = null
  $container = this

  handleMouseDown = (e) ->
    if e.which != 1
      return
    # only handle left mouse button
    dragging = true
    clicking = true
    draggedLi = $(this).closest('li.draggable')
    $container.find('.handle .hovering').removeClass 'hovering'
    return false

  $(document).on 'mouseup', (e) ->
    if e.which != 1
      return
    # only handle left mouse button
    if dragging and !clicking
      if !$.contains(draggedLi.get(0), placeholder.get(0))
        placeholder.replaceWith draggedLi
        moveCallback draggedLi
      else
        # dragged to self or descendant of self, just remove placeholder
        placeholder.remove()
      dragging = false
      draggedLi = null
    else if clicking
      $this = $(e.target).closest('li')
      $container.find('li.draggable').removeClass 'moving'
      $this.addClass 'moving'
      if placeholder != null
        $(placeholder).remove()
      placeholder = $('<li class=\'placeholder\'></li>')
      $this.before placeholder
      clicking = false
      dragging = false
    return
  $container.find('.handle *').off('mousedown').on 'mousedown', handleMouseDown
  $container.find('li.draggable').off('mousemove').on 'mousemove', (e) ->
    if dragging
      if clicking
        if placeholder != null
          placeholder.remove()
          $('li.draggable.moving').removeClass 'moving'
        placeholder = $('<li class="placeholder"></li>')
        draggedLi.after placeholder
        clicking = false
        # once moved a release won't count as click
      $this = $(this).closest('li.draggable')
      parentOffset = $this.offset()
      relY = e.pageY - (parentOffset.top)
      height = $this.height()
      if relY > height / 2
        $this.after placeholder
      else
        $this.before placeholder
    return false

  document.onkeydown = (e) ->
    if placeholder == null
      return true
    if $(document.activeElement).attr('type') == 'text'
      return true
    # in a textbox, so do nothing
    prevOffset = $(placeholder).offset()
    e = e or window.event
    if e.keyCode == '38'
      # up arrow
      prev = $(placeholder).prev()
      if prev.length > 0
        prev.before placeholder
    else if e.keyCode == '40'
      # down arrow
      next = $(placeholder).next()
      if next.length > 0
        next.after placeholder
    else if e.keyCode == '13'
      # enter key
      moving = $('li.moving')
      $(placeholder).replaceWith moving
      moving.removeClass 'moving'
      moveCallback moving
    else
      return
    newOffset = $(placeholder).offset()
    curScrollTop = $(document).scrollTop()
    newScrollTop = curScrollTop + newOffset.top - (prevOffset.top)
    $('html, body').animate { scrollTop: newScrollTop }, 'fast'
    return false

  return this
