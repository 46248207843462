initImagePicker = (elem, maxWidth) ->
	elem.click ->
		id = $(this).data("image-picker")

		$('#image-modal').modal 'show'

		$('[data-selector=\'insertable-image\']').off('click').click ->
			$this = $(this)

			if maxWidth
				$.post($("#image-modal").data("resize-url"), {
					url: $this.data("src"),
					maxWidth: maxWidth
				}, (data, response) ->
					$("[data-image-input='" + id + "']").val(data.url)
					console.log "resized image successfully"
				).fail(() ->
					$("[data-image-input='" + id + "']").val($this.data('src'))
					console.log "failed to resize image"
				)
			else
				$("[data-image-input='" + id + "']").val($this.data('src'))
				console.log "didn't resize image"

			$("[data-image-picker-preview='" + id + "']").attr("src", $this.data('src'))
			$('.modal.in').modal 'hide'
			return false
		return false