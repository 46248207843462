searchData = null
searchDataLoading = false

# top bar search
populateSearchResults = ->
  q = $('#top-search').val().toLowerCase()
  if q.length < 1
    $('[data-selector=\'search-results\']').empty()
  terms = q.split(/[\s]+/)
  res = _.filter(searchData, (sd) ->
    lcName = sd.Name.toLowerCase()
    lcType = sd.ItemType.toLowerCase()
    _.every terms, (term) ->
      lcTerm = term.toLowerCase()
      lcName.indexOf(lcTerm) > -1 or lcType.indexOf(lcTerm) > -1
  )
  templateInto '[data-selector=\'search-results-template\']', { results: res }, '[data-selector=\'search-results\']'

loadSearchData = ->
  searchDataLoading = true
  $("[data-selector='search-spinner']").show()
  $.ajax
    type: 'GET'
    url: $('[data-selector=\'search-results\']').data('action')
    success: (data) ->
      searchData = data
      populateSearchResults()
      $("[data-selector='search-spinner']").hide()
    error: ->
      addNotification 'Zoekresultaten ophalen mislukt', true

$ ->
  $('#top-search').keyup _.throttle(((ev) ->
    if ev.keyCode == 27
      # escape key
      $('[data-selector=\'search-results\']').empty()
      return
    else if ev.keyCode == 13
      ev.preventDefault()
      return true
    if searchData == null
      if !searchDataLoading
        loadSearchData()
    else
      populateSearchResults()
  ), 100)

  $('#top-search').blur ->
    # hide search results after a short delay. The delay is so that a click on a search result will still be processed
    _.delay (->
      $('[data-selector=\'search-results\']').empty()
    ), 300

  $('#top-search').focus ->
    populateSearchResults()