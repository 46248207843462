$(document).ready ->
  $('#side-menu').metisMenu()

  # Collapse ibox function
  $('.collapse-link:not(.binded)').addClass('binded').click ->
    ibox = $(this).closest('div.ibox')
    button = $(this).find('i')
    content = ibox.find('div.ibox-content')
    content.slideToggle 200
    button.toggleClass('fa-chevron-up').toggleClass 'fa-chevron-down'
    ibox.toggleClass('').toggleClass 'border-bottom'
    setTimeout (->
      ibox.resize()
      ibox.find('[id^=map-]').resize()
    ), 50

  # minimalize menu
  $('.navbar-minimalize:not(.binded)').addClass('binded').click ->
    $('body').toggleClass 'mini-navbar'
    SmoothlyMenu()

  # Move modal to body
  # Fix Bootstrap backdrop issu with animation.css
  $('.modal').appendTo 'body'

  $('[data-toggle=popover]').popover()

  $(window).bind 'load resize', ->
    if $(this).width() < 769
      $('body').addClass 'body-small'
    else
      $('body').removeClass 'body-small'

window.SmoothlyMenu = ->
  if !$('body').hasClass('mini-navbar') or $('body').hasClass('body-small')
    # Hide menu in order to smoothly turn on when maximize menu
    $('#side-menu').hide()
    # For smoothly turn on menu
    setTimeout (->
      $('#side-menu').fadeIn 500
      return
    ), 100
  else if $('body').hasClass('fixed-sidebar')
    $('#side-menu').hide()
    setTimeout (->
      $('#side-menu').fadeIn 500
      return
    ), 300
  else
    # Remove all inline style from jquery fadeIn function to reset menu state
    $('#side-menu').removeAttr 'style'