setCoords = (imgUploader, c) ->
  $('input[name=\'' + imgUploader.data('crop-prefix') + 'X1\']').val Math.round(c.x)
  $('input[name=\'' + imgUploader.data('crop-prefix') + 'X2\']').val Math.round(c.x2)
  $('input[name=\'' + imgUploader.data('crop-prefix') + 'Y1\']').val Math.round(c.y)
  $('input[name=\'' + imgUploader.data('crop-prefix') + 'Y2\']').val Math.round(c.y2)

onFileSelected = (imgUploader, event) ->
  selectedFile = event.target.files[0]
  reader = new FileReader
  imgtag = $('[data-crop-target-for=\'' + imgUploader.attr('name') + '\']')
  imgtag.attr 'title', selectedFile.name

  reader.onload = (event) ->
    imgtag.attr('src', event.target.result).on 'load', ->
      imgtag.Jcrop(
        onChange: (coords) ->
          setCoords imgUploader, coords
        onSelect: (coords) ->
          setCoords imgUploader, coords
          $('.jcrop-box').attr 'type', 'button'
        aspectRatio: imgUploader.data('crop-aspect-ratio')
        boxWidth: imgUploader.data('crop-width') or 0
        boxHeight: imgUploader.data('crop-height') or 0
      )
    $(this).off 'onload'

  reader.readAsDataURL selectedFile

$ ->
  $('[data-croppable=\'true\']').each (ix, val) ->
    $val = $(val)
    $val.change (event) ->
      onFileSelected $val, event

    $.each ['X1', 'X2', 'Y1', 'Y2'], (ix2, coord) ->
      $('<input>').attr('type', 'hidden').attr('name', $val.data('crop-prefix') + coord).appendTo $val.parent()
