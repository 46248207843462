MenuEditor = (hideUnhideHandler, targetBlankHandler, deleteHandler) ->
    dragging = false
    clicking = false
    draggedLi = null
    ancestor = null
    currentlyEditing = null
    placeholder = null
    self = this

    # move item from the menu to outside
    @demote = (item) ->
        itemId = item.data('id')
        $.post window.location, {
            action: 'demote'
            child: itemId
        }, (data, response) ->
            if data.error != undefined
                addNotification data.error, true
                item.remove()
            else
                addNotification data.Message, false
                item.find('li').addBack().each (ix, elem) ->
                    actionid = $(elem).data('actionid')
                    $('[data-selector=\'orphan-draggable\'] li[data-actionid=\'' + actionid + '\']').removeClass 'disabled'
                    return
                item.remove()
                if $('ol[data-selector=\'menu-draggable\'] li:visible').size() == 0
                    $('ol[data-selector=\'menu-draggable\'] li.dummy').show()
                # hide the dummy orphan
                $('[data-selector=\'orphan-draggable\'] li.dummy').hide()
            return
        return

    # the item provided by the parameter is released, update the DOM and tell the server
    finishMove = (item) ->
        itemId = item.data('id')
        parentId = null
        if !placeholder.parent().hasClass('draggable')
            parentId = placeholder.parent().parent().data('id')
        fromMenu = ancestor.data('selector') == 'menu-draggable'
        toMenu = placeholder.closest('.draggable').data('selector') == 'menu-draggable'
        index = placeholder.index()
        if fromMenu and toMenu
            $.post window.location, {
                action: 'move'
                child: itemId
                parent: parentId
                position: index
            }, (data, response) ->
                if data.error != undefined
                    addNotification data.error, true
                    alert 'Error while moving menu item, please refresh the page.'
                    item.remove()
                else
                    placeholder.replaceWith item
                    addNotification data.Message, false
                return
        else if fromMenu and !toMenu
            # the self.demote method is separated from this method, because it's also called by the delete buttons
            self.demote item
            placeholder.remove()
        else if !fromMenu and toMenu
            $.post window.location, {
                action: 'promote'
                child: item.data('actionid')
                parent: parentId
                position: index
            }, (data, response) ->
                if data.error != undefined
                    addNotification data.error, true
                else
                    addNotification data.Message, false
                    $('[data-selector=\'menu-draggable\'] li.dummy').hide()
                    tmplHtml = $('#menu-item-template').html()
                    newContent = _.template(tmplHtml)(
                        menuLabel: item.data('menulabel')
                        actionId: item.data('actionid')
                        isPagePlugin: item.data('ispageplugin')
                        isRedirect: item.data('isredirect')
                    )
                    newli = $('<li></li>')
                    newli.html newContent
                    newli.data 'id', data.menuItemId
                    newli.data 'urlid', item.data('urlid')
                    newli.data 'actionid', item.data('actionid')
                    newli.removeData 'selector'
                    newli.removeAttr 'data-selector'
                    newli.addClass 'menuitem'
                    newli.find('.handle').on 'mousedown', handleMouseDown
                    newli.find('.hide-menuitem, .unhide-menuitem').on 'click', ->
                        hideUnhideHandler self, this
                    newli.find('.internal, .external').on 'click', ->
                        targetBlankHandler self, this
                    newli.find('.delete').on 'click', ->
                        deleteHandler self, this
                    placeholder.replaceWith newli
                    newli.on 'mousemove', onLiMenuitemMouseMove
                    item.addClass 'disabled'
                    if $('ol[data-selector=\'orphan-draggable\'] li:visible').size() == 0
                        $('ol[data-selector=\'orphan-draggable\'] li.dummy').show()
                return
        return

    # process mouse-downs on handles
    # mouse-down can be the start of a click, or of a drag, but this distinction is not made on this method
    handleMouseDown = (e) ->
        if e.which != 1
            return
        # only handle left mouse button
        dragging = true
        clicking = true
        draggedLi = $(this).closest('li.menuitem')
        ancestor = draggedLi.closest('ol.draggable')
        $('.handle.hovering').removeClass 'hovering'
        return false

    $('.handle').on 'mousedown', handleMouseDown

    # handle the mouse-move event on a li. If we've mouse-downed an item and this is the first drag event
    # then apparently we're dragging and not clicking. Depending on the mouse position, update the DOM to
    # move the placeholder into the right spot.
    onLiMenuitemMouseMove = (e) ->
        if dragging
            if clicking
                if placeholder != null
                    placeholder.remove()
                    $('li.menuitem.moving').removeClass 'moving'
                placeholder = $('<li class="placeholder"></li>')
                draggedLi.after placeholder
                clicking = false # once the mouse has moved, a button release won't count as click
                
            $this = $(this).closest('li.menuitem')
            parentOffset = $this.offset()
            relY = e.pageY - (parentOffset.top)
            relX = e.pageX - (parentOffset.left)
            height = $this.height()
            if relX > 80 and $this.find('ol').is(':visible')
                # add as child
                $this.find('ol').first().append placeholder
            else
                # add as sibling
                if relY > height / 2
                    $this.after placeholder
                else
                    $this.before placeholder
        return false

    $('li.menuitem').on 'mousemove', onLiMenuitemMouseMove

    # add event handlers to the visibility, targetblank, and delete buttons
    # the methods "hideUnhideHandler", "targetBlankHandler" and "deleteHandler"
    # are provided by the page in the constructor of MenuEditor
    $('.hide-menuitem, .unhide-menuitem').click ->
        hideUnhideHandler self, this
    $('.internal, .external').click ->
        targetBlankHandler self, this
    $('.delete').click ->
        deleteHandler self, this

    # if a certain action is already in the menu, mark all these actions in the orphan menu as disabled.
    # this doesn't mean that they can never be dragged, e.g. in the sub-menu case it's allowed to drag an
    # orphan into the menu twice. Just add the class 'disabled', and it's up to the page to handle the rest.
    $('[data-selector=\'menu-draggable\'] li[data-actionid]').each (ix, val) ->
        urlid = $(val).data('actionid')
        $('[data-selector=\'orphan-draggable\'] li[data-actionid=\'' + urlid + '\']').addClass 'disabled'
        return

    # if there are no visible items in the orphan list, show the dummy which should just contain a text such as
    # 'there are no items available'
    if $('ol[data-selector=\'orphan-draggable\'] li:visible').size() == 0
        $('ol[data-selector=\'orphan-draggable\'] li.dummy').show()

    # when releasing a mouse button, this means we either clicked a handle or we released after drag.
    # in the first case, enter the keyboard move mode. In the second case, handle the movement and update the
    # DOM to add e.g. the collapsible classes
    $(document).on 'mouseup', (e) ->
        if e.which != 1
            # only handle left mouse button
            return
        
        if dragging and !clicking
            if !$.contains(draggedLi.get(0), placeholder.get(0))
                newParent = placeholder.parent().parent()
                oldParent = draggedLi.parent().parent()

                # add expand/collapse thingie to new parent if needed
                if !newParent.hasClass('collapsible')
                    newParent.addClass('collapsible').addClass('expanded')

                # remove expand/collapse thingie from old parent if needed
                if oldParent.hasClass('collapsible') and oldParent.children('ol').children().length == 1
                    oldParent.removeClass('collapsible').removeClass('expanded').removeClass 'collapsed'

                # call handler to finish DOM updating and updat the server
                finishMove draggedLi
            else
                # dragged to self or descendant of self, just remove placeholder
                placeholder.remove()
            dragging = false
            draggedLi = null
        else if clicking
            # handle has been clicked. Enter the keyboard move mode
            $this = $(e.target).closest('li')
            currentlyEditing = $this
            $('li.menuitem').removeClass 'moving'
            $('li.menuitem').removeClass 'selected'
            $this.addClass 'moving'
            if placeholder != null
                $(placeholder).remove()
            placeholder = $('<li class=\'placeholder\'></li>')
            $this.before placeholder
            clicking = false
            dragging = false
        return

    # clicking on collapse button collapses or expands a menu tree
    $(document).on 'click', '.collapse-menuitem', ->
        $(this).closest('li').toggleClass 'expanded'
        $(this).closest('li').toggleClass 'collapsed'
        return false

    # inline edit functionality of menu labels
    $(document).on 'click', 'li.menuitem span.menulabel', ->
        $this = $(this).closest('li')
        $('li.menuitem.moving').removeClass 'moving'
        $('li.menuitem.selected').removeClass 'selected'

        if placeholder != null
            $(placeholder).remove()

        if $this.closest('ol.draggable').data('selector') == 'orphan-draggable'
            # we can't edit menu labels of the orphan menu
            return true

        if !$this.hasClass('selected')
            $this.addClass 'selected'
            currentlyEditing = $this
            oldvalue = $(this).html()
            input = $('<input />',
                'type': 'text'
                'class': 'inline-edit'
                'value': oldvalue)
            $(this).after input
            $(input).keypress (e) ->
                if e.which == 13 # return key
                    $(this).blur()
                    return false
            $(this).hide()
            $(input).on 'blur', ->
                newval = $(input).val()
                if $(input).val() != oldvalue
                    $.post window.location, {
                        action: 'rename'
                        child: $this.closest('li').data('id')
                        newlabel: newval
                    }, (data, response) ->
                        if data.Error
                            addNotification data.Message, true
                        else
                            addNotification data.Message, false
                        $this.find('span.menulabel').first().show().html(data.NewLabel)
                        $this.data 'menulabel', data.NewLabel
                        $this.removeClass 'selected'
                        $(input).remove()
                        return
                else
                    $this.find('span.menulabel').first().show()
                    $this.removeClass 'selected'
                    $(input).remove()
                return
            input.focus()
        return

    # when hovering the mouse over a handle, expand it after a short while
    timer = null
    $(document).on 'mouseenter', 'li.menuitem.collapsible .handle', ->
        if dragging
            $this = $(this).closest('.handle')
            $parent = $this.closest('li')
            if !$parent.hasClass('expanded')
                $this.addClass 'hovering'
                if timer != null
                    clearTimeout timer
                timer = setTimeout((->
                    $parent.addClass 'expanded'
                    $parent.removeClass 'collapsed'
                    $this.removeClass 'hovering'
                    return
                ), 1200)
                $this.on 'mouseleave', ->
                    if timer != null
                        clearTimeout timer
                    $this.removeClass 'hovering'
                    return false
        return false

    # support for moving menu items with the keyboard
    $(document).on('keydown', (e) ->
        if placeholder == null
            # not currently moving anything
            return true

        if $(document.activeElement).attr('type') == 'text'
            # in a textbox, so do nothing
            return true

        prevOffset = $(placeholder).offset()
        e = e or window.event

        if e.which == 27 # escape key
            $('li.moving').removeClass("moving")
            $('li.placeholder').remove()
            placeholder = null
            return
        else if e.which == 37 # left arrow
            if $(placeholder).parent().hasClass('draggable')
                # at root, can't go up further
                return

            parent = $(placeholder).parent().parent()
            parent.after placeholder
        else if e.which == 38 # up arrow
            prev = $(placeholder).prev()
            if prev.length == 0
                # there is no sibling up, so go up a level
                if $(placeholder).parent().hasClass('draggable')
                    return
                # at root, can't go up further
                prev = $(placeholder).parent().parent()
            prev.before placeholder
        else if e.which == 39 # right arrow
            prev = $(placeholder).prev()
            if prev.length == 0
                return
            if prev.hasClass('collapsed')
                prev.removeClass 'collapsed'
                prev.addClass 'expanded'
            childOl = $(prev).find('ol')
            if childOl.length == 0
                return
            childOl.first().append placeholder
        else if e.which == 40 # down arrow
            next = $(placeholder).next()
            if next.length == 0
                # there is no sibling down, so go up a level
                if $(placeholder).parent().hasClass('draggable')
                    # at root, can't go up further
                    return
                next = $(placeholder).parent().parent()
            next.after placeholder
        else if e.which == 13 # enter key
            moving = $('li.moving')
            moving.removeClass 'moving'
            finishMove moving
        else
            return

        # scroll view so that placeholder is centered in screen
        newOffset = $(placeholder).offset()
        curScrollTop = document.body.scrollTop
        newScrollTop = curScrollTop + newOffset.top - (prevOffset.top)
        $('html, body').stop().animate { scrollTop: newScrollTop }, 'fast'
        return false
    )