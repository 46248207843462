slugiFy = (title) ->
  url = title.toLowerCase()
  url = url.replace(/^\s+|\s+$/g, "")
  url = url.replace(/[_|\s]+/g, "-")
  url = url.replace(/[^a-z0-9-]+/g, "")
  url = url.replace(/[-]+/g, "-")
  url = url.replace(/^-+|-+$/g, "")

  return url

(($) ->
  $.fn.limitToSlug = (allowSlash = false) ->
    $(this).keypress((event) ->
      key = event.which
      if key == 13 # enter key, ignore
        return true
      
      if key == 8 # backspace
        return true

      valid = (key > 47 and key < 58) or (key > 64 and key < 91) or (key > 96 and key < 123) or (allowSlash and key == 47) # a-z, 0-9, and the forward slash if allowSlash is true
      value = String.fromCharCode(key) # string.fromcharcode doesn't work for the forward slash key
      
      caret = $(this).getCaretPosition()
      prevChar = $(this).val().slice(caret - 1, caret)
      if valid
        $(this).insertAtCaret value.toLowerCase()
      else if prevChar != '-'
        $(this).insertAtCaret '-'

      event.preventDefault()
      return false
    ).bind 'paste', (event) ->
      $(this).insertAtCaret slugiFy(event.originalEvent.clipboardData.getData('text/plain'))
      return false
    return this
) jQuery