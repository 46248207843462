templateInto = (templateSelector, data, outputSelector) ->
  mainTemplateTxt = $(templateSelector).html()
  template = _.template(mainTemplateTxt)
  result = template(data)
  $(outputSelector).html result
  return result

appendTemplateInto = (templateSelector, data, outputSelector) ->
  mainTemplateTxt = $(templateSelector).html()
  template = _.template(mainTemplateTxt)
  result = $(template(data))
  $(outputSelector).append result
  return result

addNotification = (text, isError) ->
  if !isError
    toastr.success text
  else
    toastr.warning text

# This method applies a list of (name, value) pairs to an object, as in model binding
# names like "Foo.Bar" and "Foo[0].Bar" are supported, but _only integers are allowed between square braces_.
# That is to say, the following name is NOT ALLOWED: Foo["Bar"]
# If Foo[0] and Foo[5] are present, but 1-4 are not, then 5 will be re-numbered to 1 and the original 1-4 will be removed if present
applyFieldsToObject = (fields, object) ->
  for fieldIx of fields
    field = fields[fieldIx]
    # if there are multiple fields with the same name then only set the one with a non-empty value
    if field.value == '' and _.any(fields, ((fld) ->
        `var index`
        `var index`
        fld.value != '' and fld.name == field.name
      ))
      continue
    fieldName = field.name
    obj = object
    loop
      braceIx = fieldName.indexOf('[')
      dotIx = fieldName.indexOf('.')
      if braceIx == -1 and dotIx == -1
        break
      if braceIx != -1 and braceIx < dotIx
        # brace property access
        if braceIx == 0
          braceEndIx = fieldName.indexOf(']')
          index = fieldName.substring(braceIx + 1, braceEndIx)
          fieldName = fieldName.substring(braceEndIx + 1)
          if obj[index] == undefined
            obj[index] = {}
          obj = obj[index]
        else
          index = fieldName.substring(0, braceIx)
          fieldName = fieldName.substring(braceIx)
          if obj[index] == undefined
            obj[index] = []
          obj = obj[index]
      else
        # dot property access
        index = fieldName.substring(0, dotIx)
        fieldName = fieldName.substring(dotIx + 1)
        if index == ''
          continue
        if obj[index] == undefined
          obj[index] = {}
        obj = obj[index]
    obj[fieldName] = field.value

$ ->
  $('select[data-selector=\'tenant-choice\']').change ->
    $(this).closest('form').submit()

  $('[data-toggle-active]').on "click", ->
    $(this).toggleClass('active')

  $('[data-show]').on "click", ->
    sel = $(this).data("show")
    $(this).toggleClass("active")
    $("[data-selector='" + sel + "']").toggleClass('hidden')

  $('[data-toggle="tooltip"]').tooltip()

  toastr.options =
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "onclick": null,
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"

  jQuery.extend(jQuery.validator.messages,
      required: "Dit veld is verplicht."
      email: "Vul een geldig e-mailadres in."
      url: "Vul een geldige URL in."
      number: "Vul een getal in."
      digits: "Vul alleen cijfers in."
      accept: "Kies een bestand van het juiste type."
      maxlength: jQuery.validator.format("Niet meer dan {0} tekens gebruiken.")
      minlength: jQuery.validator.format("Gebruik tenminste {0} tekens.")
      rangelength: jQuery.validator.format("Vul een waarde in met een lengte tussen {0} en {1} tekens.")
      range: jQuery.validator.format("Vul een waarde in tussen {0} en {1}.")
      max: jQuery.validator.format("Vul een waarde in van maximaal {0}.")
      min: jQuery.validator.format("Vul een waarde in van minimaal {0}.")
  )

  if $("#notification-data").length
    nots = JSON.parse($("#notification-data").text())
    for n in nots
      if n.IsError == 0
        toastr.error(n.Text)
      else
        toastr.success(n.Text)

  ajaxFormSubmit = ->
    $.ajax
      type: 'POST'
      url: $(this).attr('action')
      data: $(this).serialize()
      success: (data) ->
        addNotification data.Message
        return
      error: (data) ->
        addNotification 'Failed', true
        return
    return false

  $('[data-type=\'ajaxform\']').not("[data-validate]").submit(ajaxFormSubmit)
  $('[data-type=\'ajaxform\'][data-validate]').each( (k,f) ->
    $(f).validate
      submitHandler: _.bind(ajaxFormSubmit, f)
  )
  $("form[data-validate]").not("[data-type='ajaxform']").each( (k,f) ->
    $(f).validate()
  )

  _dataFn = $.fn.data

  $.fn.data = (key, val) ->
    if typeof val != 'undefined'

      $.expr.attrHandle[key] = (elem) ->
        $(elem).attr(key) or $(elem).data(key)

    _dataFn.apply this, arguments