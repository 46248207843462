# Handler functions that get called when the sidebar forms are submitted for the specified node-type
propertiesSaveFunctions = 
  'A': (target, form) ->
    title = form.find('[name=\'title\']').val()
    contents = form.find('[name=\'contents\']').val()
    target.attr('title', title).text contents
    return
  'IMG': (target, form) ->
    title = form.find('[name=\'title\']').val()
    alt = form.find('[name=\'alt\']').val()
    target.attr('title', title).attr 'alt', alt
    return

currentSidebar = null
currentSidebarElement = null
currentSidebarPoller = null
editors = []
lastEditor = null

template = (templateSelector, data) ->
  mainTemplateTxt = $(templateSelector).html()
  tmpl = _.template(mainTemplateTxt)
  return tmpl data

tinyMCE.baseURL = rawContentUrl

initTinyMCE = (element, setInsertablesClickHandler) ->
  tinyMCE.init
    theme: 'modern'
    mode: 'exact'
    elements: element
    toolbar: 'formatselect | bold italic | alignleft aligncenter alignright | bullist numlist indent outdent | undo redo | code link anchor | myimage mydocument mylink'
    blockformats: 'p,h1,h2,h3'
    valid_elements: '*[*]'
    valid_children: '*[*]'
    menubar: false
    statusbar: false
    plugins: 'autolink,paste,code,link,anchor'
    relative_urls: false
    setup: (ed) ->
      editors.push ed
      ed.on 'click', (e) ->
        targetName = e.target.nodeName
        template = $('[data-type=\'edit-sidebar\'][data-elemtype=\'' + targetName + '\'][data-edit=\'true\']').html()
        if template
          compiled = _.template(template)
          currentSidebar = $('#element-properties-sidebar').html(compiled($(e.target)))
          $('#element-properties-sidebar').modal 'show'
          $('#element-properties-sidebar form').submit((ev) -> ev.preventDefault())
          $('#element-properties-sidebar form').validate(
            submitHandler: () ->
              propertiesSaveFunctions[targetName] $(e.target), $(this)
              $('#element-properties-sidebar').modal 'hide'
              return false
          )
          # we need to hide the sidebar when the element that it refers to gets deleted,
          # and there doesn't seem to be a way to do this without periodically checking for
          # the precence of this object. To check if it's still present we need to check whether
          # it's contained in the tinymce, because when we have <p><a ...>foo</a></p> then we
          # are referencing the <a> but the <p> gets deleted at the same time so the parent is
          # not null.
          currentSidebarElement = e.target
          if currentSidebarPoller == null
            currentSidebarPoller = setInterval((->
              if $(currentSidebarElement).closest('#tinymce').length == 0
                clearInterval currentSidebarPoller
                currentSidebarPoller = null
                currentSidebar.fadeOut()
              return
            ), 100)

      ed.addButton 'myimage',
        title: 'Insert image'
        image: contentUrl('tmce_image.gif')
        onclick: ->
          ed.focus()
          $('#image-modal').modal 'show'
          setInsertablesClickHandler ed
          return false

      ed.addButton 'mydocument',
        title: 'Insert document'
        image: contentUrl('tmce_document.gif')
        onclick: ->
          ed.focus()
          $('#document-modal').modal 'show'
          setInsertablesClickHandler ed
          return false

      ed.addButton 'mylink',
        title: 'Insert link'
        image: contentUrl('tmce_link.gif')
        onclick: ->
          ed.focus()
          $('#link-modal').modal 'show'
          setInsertablesClickHandler ed
          return false

      ed.on 'init', ->
        html = $(ed.contentDocument).find('html')
        html.css 'height', '100%'
        html.find('body').css 'height', '100%'

insertThing = (editor, templateFunc, elemType) ->
  elemHtml = templateFunc()
  img = $(elemHtml)[0]
  template = $('[data-type=\'edit-sidebar\'][data-elemtype=\'' + elemType + '\']').html()
  currentSidebar = $('#element-properties-sidebar').html(_.template(template)($(img)))
  $('.modal.in').modal 'hide'
  $('#element-properties-sidebar').modal 'show'
  $('#element-properties-sidebar form').submit (ev) ->
    ev.preventDefault()
    propertiesSaveFunctions[elemType] $(img), $(this)
    $('#element-properties-sidebar').modal 'hide'
    editor.execCommand 'mceInsertContent', false, img.outerHTML
    return false

setInsertablesClickHandler = (editor) ->
  # when no editor is specified then call the function on the editor for which the function was last called.
  # the last called editor was the one on which the "insert x" button was clicked
  editor = editor or lastEditor
  lastEditor = editor

  $('[data-selector=\'insertable-image\']').off('click').click ->
    $this = $(this)
    insertThing editor, (->
      elemTemplate = $('#image-template').html()
      tmpl = _.template elemTemplate
      return tmpl(
        src: $this.data('src')
        title: $this.data('title')
        description: $this.data('description')
      )
    ), 'IMG'
    return false

  $('[data-selector=\'insertable-file\']').off('click').click ->
    $this = $(this)
    $this.closest('.modal').hide()
    insertThing editor, (->
      elemTemplate = $('#file-template').html()
      tmpl = _.template elemTemplate
      return tmpl(
        src: $this.data('src')
        title: $this.data('title')
      )
    ), 'A'
    return false

  $('[data-selector=\'insertable-link\']').off('click').click ->
    $this = $(this)
    $this.closest('.modal').hide()
    insertThing editor, (->
      elemTemplate = $('#link-template').html()
      tmpl = _.template elemTemplate
      return tmpl(
        src: $this.data('src')
        title: $this.data('title')
      )
    ), 'A'
    return false

$ ->
  jcrop_api = null # keep track of the jcrop instance, so we can remove it when a new one is needed

  $('textarea[data-tinymce]').each (ix, elem) ->
    if !elem.id
      elem.id = 'tinymce_' + ix
    initTinyMCE elem.id, setInsertablesClickHandler

  setCoords = (imgUploader, c) ->
    $('input[name=\'' + imgUploader.attr('name') + 'X1\']').val Math.round(c.x)
    $('input[name=\'' + imgUploader.attr('name') + 'X2\']').val Math.round(c.x2)
    $('input[name=\'' + imgUploader.attr('name') + 'Y1\']').val Math.round(c.y)
    $('input[name=\'' + imgUploader.attr('name') + 'Y2\']').val Math.round(c.y2)

  onFileSelected = (imgUploader, ev) ->
    selectedFile = ev.target.files[0]
    reader = new FileReader
    imgtag = $('[data-crop-target-for=\'' + imgUploader.attr('name') + '\']')
    imgtag.attr 'title', selectedFile.name

    reader.onload = (ev) ->
      imgtag.show()
      imgtag.parent().find('jcrop-holder').hide()
      imgtag.attr 'src', ev.target.result

      if jcrop_api
        jcrop_api.destroy()

      imgtag.Jcrop(
        onChange: (coords) ->
          setCoords imgUploader, coords
          $('.jcrop-box').attr 'type', 'button'
        onSelect: (coords) ->
          setCoords imgUploader, coords
          $('.jcrop-box').attr 'type', 'button'
        boxWidth: 400
      , () ->
        # ready callback
        jcrop_api = this
      )

    reader.readAsDataURL selectedFile

  # image uploader
  imgUploadEndpoint = $('[data-selector=\'image-uploader\']').data('endpoint')
  $val = $('[data-selector=\'image-uploader\']')
  $val.change (ev) ->
    onFileSelected $val, ev

  $.each ['X1', 'X2', 'Y1', 'Y2'], (ix2, coord) ->
    $('<input>').attr('type', 'hidden').attr('name', "file" + coord).appendTo $val.parent()

  $('[data-selector=\'image-upload-form\']').submit((ev) -> ev.preventDefault())
  $('[data-selector=\'image-upload-form\']').validate(
    submitHandler: (form) ->
      $('[data-crop-target-for=\'file\']').hide()
      $('[data-crop-target-for=\'file\']').parent().find('.jcrop-holder').hide()

      data = new FormData(form)

      $.ajax(
        method: "POST"
        url: imgUploadEndpoint
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        success: (data, textStatus, jqXHR) ->
          tmpl = _.template($('[data-selector=\'image-template\']').html())(data)
          $('[data-selector=\'image-list\']').append tmpl
          setInsertablesClickHandler()
        error: (jqXHR, textStatus, errorThrown) ->
          alert("Upload failed!")
      )
      return false
  )

  # file uploader.
  fileUploadEndpoint = $('[data-selector=\'file-uploader\']').data('endpoint')
  
  $('[data-selector=\'file-upload-form\']').submit((ev) -> ev.preventDefault())
  $('[data-selector=\'file-upload-form\']').validate(
    submitHandler: (form) ->
      data = new FormData(form)

      $.ajax(
        method: "POST"
        url: fileUploadEndpoint
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        success: (data, textStatus, jqXHR) ->
          tmpl = _.template($('[data-selector=\'file-template\']').html())(data)
          $('[data-selector=\'file-list\']').append tmpl
          setInsertablesClickHandler()
        error: (jqXHR, textStatus, errorThrown) ->
          alert("Upload failed!")
      )
      return false
  )

  $('[data-selector="insert-cancel"]').click ->
    $(this).closest('.modal').modal('hide')
    return false
